@import 'react-checkbox-tree/lib/react-checkbox-tree.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #f9fcff;
  background-image: linear-gradient(147deg, #f9fcff 0%, #dee4ea 74%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  max-width: 100%;
}

#footer {
  /* margin-top: 50vh!important; */
}

#filters .row.filter {
  border-bottom: 1px solid orange;
}

#filters .multi-range-slider {
  margin-bottom: 1em;
}

#priceFilter.multi-range-slider {
  border: none;
  box-shadow: none;
}

.multiSelectContainer .chip {
  background: orange;
  color: #000;
  font-size: .5em;
}

.multiSelectContainer li.option {
  font-size: .7em;
}

.multiSelectContainer li.option:hover {
  background: orange;
  color: #000;
}

#searchResults thead {
  position: sticky;
  top: 0px;
}

#searchResults th {
  font-size: .75em;
  padding: 0.1em .3em;
  background-color: orange;
}

#searchResults td {
  font-size: .8em;
  font-family: 'Courier New', Courier, monospace;
  color: #000;
  vertical-align: middle;
}

#searchResults td.col-stock {
  text-align: center;
}

#searchResults td.col-pret {
  text-align: right;
  font-weight: 600;
}

#searchResults td.col-qty {
  text-align: center;
  padding: 4px;
}

#searchResults input.qty-input {
  font-size: .9em;
  width: 3em;
  padding: 0;
  margin: 0 auto;
  text-align: center;
  border: 1px dotted orange;
}

#searchResults .input-group {
  max-width: 6em;
  text-align: center;
}

#searchResults .input-group input.qty-input {
  width: 1%;
}

#searchResults td.col-add {
  text-align: center;
}

#searchResults .addCartConfirmed {
  color: green;
  font-size: 18px;
  margin: 6px auto 7px auto;
}

#searchResults td.col-info {
  text-align: center;
  color: darkgray;
}

#searchResults td.col-price {
  text-align: right;
}

.carousel-caption {
  background-color: #ffa500bf;
}

.carousel-caption h3 {
  color: black;
  font-weight: bold;
  font-weight: 2em;
}

.carousel-caption p {
  color: black;
  font-size: 1em;
}

.carousel-item h2 {
  position: absolute;
  right: 15%;
  top: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: orange;
  text-align: left;

  font-weight: 900;
  font-size: 3em;

  text-shadow: 0 0 8px black;
}

.carousel-item img.slide {
  width: 100% !important;
  height: auto;
  object-fit: cover;
  max-height: 60vh;
}

#search-navbar-nav a.nav-link {
  color: #fff;
}

#admin-menu {
  color: lightgray;
  font-size: 1em;
  min-height: 90vh;
}

#admin-menu h4 {
  font-size: .7em;
  margin: 0.8em 0.5em 0.5em 0.8em;
  color: white;
  border-top: 1px dotted #444;
  padding-top: 1em;
  text-transform: uppercase;
}

#admin-menu a.nav-link {
  font-size: .75em;
  color: lightgray;
  border-bottom: 1px solid rgb(40, 40, 40);
  margin: 0px;
  padding: .5em .8em;
}

#admin-menu a.nav-link:hover {
  color: #000;
  background-color: gray;
}

#admin-menu a.nav-link.active,
#admin-menu a.nav-link.active:hover {
  color: orange;
  background-color: #000;
}

#admin-menu h3 {
  padding: 0px;
  margin: 0px;
}

#admin-menu h3 a.nav-link,
#admin-menu h3 a.nav-link.active {
  font-size: .5em;
  color: #fff;
  background-color: transparent;
}

#admin-menu a svg {
  margin: 0px 4px 4px 4px;
  font-size: 1.3em;
  color: #9f9f9f;
}

#admin-menu a.nav-link.active svg,
#admin-menu a.nav-link.active:hover svg {
  color: orange;
}

#admin-menu a.nav-link:hover svg {
  color: #333;
}

.react-bootstrap-table th>.caret {
  background-color: orange;
}

.react-bootstrap-table th>.react-bootstrap-table-sort-order>.caret,
.react-bootstrap-table th .order>.dropdown>.caret,
.react-bootstrap-table th .dropdown .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}


.react-bootstrap-table th .order>.dropup>.caret,
.react-bootstrap-table th .dropup .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-bottom: 4px dashed;
  border-bottom: 4px dashed\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.react-bootstrap-table-pagination .dropdown-menu li.dropdown-item a {
  text-decoration: none;
  cursor: pointer;
}

.react-bootstrap-table-sort-order .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-bottom: 4px dashed;
  border-bottom: 4px dashed\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.react-bootstrap-table-pagination .dropdown-menu .dropdown-item:focus,
.dropdown-item:hover {}

.react-bootstrap-table .filter .sr-only,
.react-bootstrap-table .filter-label .sr-only {
  display: none;
}


.react-bootstrap-table .filter input,
.react-bootstrap-table .filter-label input {
  font-size: .7em;
}

.react-bootstrap-table .filter select,
.react-bootstrap-table .filter-label select {
  font-size: .7em;
}

.react-bootstrap-table .table-condensed {
  font-size: .8em;
}

.react-bootstrap-table .table-condensed td,
.react-bootstrap-table .table-condensed th {
  padding: .3em .5em;
  vertical-align: middle;
  word-break: break-all;
}

#number-filter-comparator-nrEq,
#number-filter-comparator-nrEg,
#number-filter-comparator-promotie,
#number-filter-comparator-nrFiles {
  display: none;
}

.row-expansion-style h3 {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
}

.row-expansion-style h4 {
  font-size: 1.1em;
  font-weight: normal;
  font-style: italic;
  color: #444;
}

.form-check-input:checked {
  background-color: orange;
  border-color: black;
}

.form-check-input:focus {
  border-color: black;
}

.form-switch {
  cursor: pointer;
}

#searchType {
  margin: 0px 2em 0px 0.5em;
}

#searchType label {
  color: #6c757d;
  font-size: 0.9em;
  margin: 3px;
}

#login-link {
  border-left: 1px solid orange;
}


@media (max-width: 768px) {
  
  #login-link {
    border-left: 0px none orange;

    color: orange;
  }
  
  #search-navbar-nav  {
    position: absolute;
    top: 12px;
    right: 22px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: center;
    align-content: flex-start;
    flex-direction: row;
  }
  
  #search-navbar-menu {
    text-align: right;
    padding-right: 15px;  
  }
  
  .collapse:not(.show) #cart-link {
    display: block!important;
  }

  /* Default styles */
  #searchGroup {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  #searchGroup>.input-row {
    display: flex;
    align-items: center;
  }

  #search-bar {
    flex: 0 0 90%;
    /* Take up 90% of the row */
    margin-right: 5px;
    /* Small gap between the input and button */
  }

  .search-button {
    flex: 0 0 10%;
    /* Take up 10% of the row */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem 0.5rem;
    /* Small size for the button */
  }

  #searchType {
    display: flex;
    flex-direction: row;
    justify-content: center;

    margin: 0px;
    padding: 0px;    
    position: absolute;
    bottom: -33px;
    left: 10px;
  }


  #search-bar,
  .search-button {
    flex: 1;
    /* Both elements take equal space on small screens */
  }

  .search-button {
    margin-left: 0;
    margin-top: 5px;
    /* Space above the button if wrapping occurs */
  }
  
  nav.bg-dark {
    padding-bottom: 33px;
  }

  #search-button {
    padding: 0.5rem; /* Adjust padding as needed */
    width: 40px;
    overflow: hidden;
    text-wrap: nowrap;
  }

  #searchButtonText {
    display: none;
  }

}

/** filter tray **/
/* Container for the filter tray */
.filter-tray-container {
  width: 100%;
  position: relative;
}

/* Button to toggle the filter tray (only visible on mobile) */
.filter-toggle-button {
  display: none; /* Hidden by default */
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.5rem;
  margin-top: 5px;
  background: orange!important; 
  color: black!important;
  border: 1px solid black; 
}

/* Collapsible tray (only visible on mobile) */
.filter-tray-collapse {
  display: none; /* Hidden by default */
}

.filter-tray {
  padding: 1rem;
  background: #ffffff; /* White background for contrast */
  border: 0px solid gray; /* Border matching Bootstrap's default */
  border-top: none; /* Remove top border to merge with button */
  box-shadow: 0px 0.5px 1px #888888;
}

/* Expanded tray (visible on non-mobile devices) */
.filter-tray-expanded {
  display: none; /* Hidden by default */
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  #filters {
    padding: 0px;
    margin: 0px;
  }
  
  .filter-toggle-button {
    display: flex; /* Show toggle button on mobile */
  }

  .filter-tray-collapse {
    display: block; /* Allow collapsing behavior on mobile */
  }

  .filter-tray-expanded {
    display: none; /* Hide expanded filters on mobile */
  }
}

/* Non-mobile styles */
@media (min-width: 769px) {
  .filter-toggle-button {
    display: none; /* Hide toggle button on larger screens */
  }

  .filter-tray-collapse {
    display: none; /* Disable collapsible tray on larger screens */
  }

  .filter-tray-expanded {
    display: block; /* Show all filters expanded on larger screens */
  }
}




.imgShadow {
  -moz-box-shadow: 10px 10px 5px #ccc;
  -webkit-box-shadow: 10px 10px 5px #ccc;
  box-shadow: 10px 10px 5px #ccc;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
}


.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  /* padding: 20px 25px 30px; */
  margin: 0 auto 25px;
  margin-top: 20px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.card-header.selected {
  background-color: #ffa500;
}

.card .card-text {
  font-size: .8em;
}

.card .h5 {
  font-size: 1.1em;
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

#footer {
  font-size: .9em;
}

#footer h5 {
  color: orange;
  margin-bottom: .8em;
  font-size: 1.3em;
}

#footer a,
#footer a:link,
#footer a:visited {
  text-decoration: none;
  color: #ccc;
}

#footer a:hover {
  color: orange;
}

#footer ul {
  padding: 0;
}

#footer li {
  list-style: none;
  padding: 0;
}

#footer .social {
  font-size: 2.5em;
  margin-right: .5em;
}

#footer .copyright {
  border-top: 1px solid #666;
  margin-top: 2em;
  font-size: .8em;
}

/*
.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;

  .card {
    display: inline-block;
  }
}
*/

.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  .item {
    flex: 0 0 auto;
  }
}

.scrolling-wrapper {
  -webkit-overflow-scrolling: touch;
}

.scrolling-wrapper::-webkit-scrollbar {
  display: none;
}

.minibanner .item {
  display: flex;
  border-right: 1px solid orange;
}

.minibanner .item:last-child {
  border: none;
}

.minibanner .item h4 {
  font-size: .8em;
  margin: 0px 0px 0px 10px;
  padding: 0px 0px 0px 0px;
  color: orange;
  font-weight: bold;
}

.minibanner .item h4 {
  font-size: .9em;
  margin: 5px 0px 0px 10px;
  padding: 0px 0px 0px 0px;
  color: orange;
  font-weight: bold;
}

.minibanner .item .desc {
  font-size: .8em;
  margin: 0px 0px 0px 10px;
  padding: 0px 0px 0px 0px;
}

.popover {
  box-shadow: 4px 4px 10px black;
}

.popover h3 {
  background-color: #000;
  color: orange;
}

.popover-arrow::after {
  border-color: black;
}

.stock-tooltip {
  font-size: 0.5em;
  text-align: left;
}

.stock-tooltip  > .tooltip-inner {
  width: 140px;
}



.table>tbody>tr.row-odd>* {
  background-color: #f2f2f2; 
}

.table>tbody>tr.row-even>* {
  background-color: #ffffff; 
}

.container-fluid.page-login,
.container-fluid.page-cerere {
  padding-left: 0px;
  padding-right: 0px;
}

#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

#background-video {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

.page-profile .card-header {
  background-color: black;
  color: orange;
}

.pagination .page-link{
  font-size: .7em;
  color: black;
  background-color: #d8e2ef;
  border: 1px solid white;

}

.pagination .active>.page-link{
  color: orange;
  font-weight: bold;
  background-color: black; 
  border: 1px solid black;
}

.react-bs-table-sizePerPage-dropdown .btn{
  font-size: .7em;
}

.dropdown .dropdown-item a{
  font-size: .7em;

  color: black;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300;700&display=swap');

#product-menu {
  font-family: 'Open Sans Condensed', sans-serif;
  background-color: #eee!important;
  border-bottom: 1px solid #333;
  font-size: 12px;
  margin: 0px 0px 15px 0px;
}

#product-menu .navbar-nav {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

#product-menu .nav-item {
  position: relative;
}

#product-menu .dropdown-menu {
  border-radius: 0;
  padding: 0;
}

#product-menu .dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

#product-menu .dropdown-submenu {
  position: relative;
}

#product-menu .dropdown-menu > .dropdown-submenu {
  position: relative;
}

#product-menu .dropdown-menu > .dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: 0;
}

#product-menu .nav-item span.nav-link {
  font-size: 13px;  
  color: #333;
  letter-spacing: .8px;
  font-weight: bold;
}

#product-menu .nav-item span.nav-link.active {
  color: orange;
}

#product-menu .dropdown-item,
#product-menu .dropdown-toggle {
  padding: 4px 10px;
  margin: 0px;
  font-size: 12px;
  font-weight: normal;
  color: #333;
  white-space: nowrap;
}

#product-menu .dropdown-item:hover,
#product-menu .dropdown-toggle:hover {
  background-color: #eee;
  cursor: pointer;
}

#product-menu li.nav-item {
  padding: 0px;
} 

#product-menu .dropdown-item.active,
#product-menu li.nav-item.active,
#product-menu .dropdown-toggle.active,
#product-menu li.dropdown-submenu.active {
  color: orange;  
  background-color: black;
  font-weight: bold;
}

.menu-edit button {
  font-size: 12px;
  padding: 0px 5px;
  margin: 0px;
}
.menu-edit td {
  padding: 5px 5px;
}